import React, { useState, useEffect } from "react";
import showAlert from "../CustomComponents/customAlert"
import axios from 'axios';   
import { URL, API_KEY } from '../links/links';
import { registerVisit } from './registerVisit'

export default class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: process.env.NODE_ENV === 'development' ? "@12345678" : "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      show: false,
      showLogin: false,
      showDelete: false,
      error: false,
      sent: null,
      items: [],
      session: "",
      isUserActive: "",
      userEmail: "",
      user: "",
      dataIsLoaded: false,
    }
  }
  componentDidMount() {
    const loggedUser = localStorage.getItem("userEmail")
    if (loggedUser) {
      const getUser = loggedUser
      this.setState({ user: getUser })
    }
    let Data = {
      page: "connexion"
    };

    registerVisit(Data)
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleCloseLogin = () => this.setState({ showLogin: false });
  handleShowLogin = () => this.setState({ showLogin: true });

  loginUser(event) {
    event.preventDefault();

    let Email = this.state.email;
    let Password = this.state.password;
    const CheckEmail = RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i);

    if ((Email.length == 0) || (Password.length == 0)) {
      showAlert({title: 'info', text: 'Please fill the mandatory fields',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else if (!(CheckEmail).test(Email)) {
      showAlert({title: 'info', text: 'email invalid',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else {

      const URLAPI = `${URL}user/login`

      let headers = {
        'Content-Type': 'application/json',
        'key': API_KEY
      }

      let Data = {
        email: Email,
        password: Password
      };
      fetch(URLAPI, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(Data) //convert data to JSON
      })
        .then(res => res.json())
        .then(
          (response) => {
            if (parseInt(response.status) == 200) {
              showAlert({title: 'success', text: 'Login avec succès',icon: 'success',confirmButtonText: 'ok',showConfirmButton: false,showCancelButton: false, position: 'top-end', timer: 10000})
              localStorage.setItem('userEmail', Email)
              let emailUserInLS = localStorage.getItem('userEmail')
              this.setState({
                userEmail: emailUserInLS
              })
              this.setState({ user: localStorage.getItem("userEmail") })

              this.handleCloseLogin()
              this.componentDidMount();
              window.location.reload(true);
              if (this.props.history) {
                this.props.history.replace('/projects')
              }
            }
            else {
              showAlert({title: 'info', text: 'Email et/ou mot de passe incorrect',icon: 'warning',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
              return 
            }
          },
          (error) => {
            showAlert({title: 'error', text: 'error connexion to the server',icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
            throw new Error('error')
          }
        )
    }
  }
  /* submit form */
  async createUser(event) {
    event.preventDefault();

    //verify entries
    let Email = this.state.email
    let Password = this.state.password
    let ConfirmPassword = this.state.confirmPassword
    let FirstName = this.state.firstName
    let LastName = this.state.lastName;
    // let Address = this.state.address
    // let Phone = this.state.address
    //chek email
    const CheckEmail = RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i);

    if ((Email.length === 0) || (FirstName.length === 0) || (LastName.length === 0) || (Password.length === 0) || (ConfirmPassword.length == 0)) {
      showAlert({title: 'info', text: 'Fill the mandatory fields',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
      
    } else if (!(CheckEmail).test(Email)) {
      showAlert({title: 'info', text: 'email invalid',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    }
    // Password validations
    else if (Password.length < 8) {
      showAlert({title: 'info', text: 'Minimum 8 characters for your Password',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else if (!((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(Password))) {
      showAlert({title: 'info', text: 'Please use a special characters for your password, example :@#$%^&*',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else if (((/[ ]/).test(Password))) {
      showAlert({title: 'info', text: 'Please delete espace in your password ',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else if (Password !== ConfirmPassword) {
      showAlert({title: 'info', text: 'Password not equal the confirm password',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
    } else {
      //call the rest api
      const URLAPI = `${URL}user/signup`;      

      let Data = {
        email: this.state.email,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        address: this.state.address,
        phone: this.state.phone,
      }
      try {
        const response = await axios.post(URLAPI, Data, {
          headers: {
            'Content-Type': 'application/json',
            'key': API_KEY
          }
        });
        const code = parseInt(response.status)
        if (code === 201) {

          showAlert({title: 'success', text: 'Utilisateur crée avec succès, veuillez cliquer sur le bouton login pour vous authentifier',icon: 'success',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false, position: 'top', timer: 5000})
          this.handleClose()
        } else {
          showAlert({title: 'error', text: "Erreur lors de la création de l'utilisateur",icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
        }
      } catch (error) {
        if (error.response.status === 409) {
          showAlert({title: 'info', text: "Utilisateur existe déjà",icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
        }else{
          showAlert({title: 'error', text: "error connexion to the server, please contacte the administrator",icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          
        }
      }
    }
  }

  render() {
    const { dataIsLoaded, items, user, userEmail } = this.state;
    if (user) {
      return (
        <>
          <h4> {user} is logged  </h4>
        </>
      )
    }
    else {
      return (
        <>
          <button className="custom-primary-btn" onClick={this.handleShowLogin}>Login</button>
          <a href="#" onClick={this.handleShow}>Don't have an account? Signup</a>

          {/* modal login */}
          <div>
            {this.state.showLogin && (
              <div className="modal-overlay" onClick={this.handleCloseLogin}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-header">
                    <h5 className="modal-title">Login</h5>
                    <button className="close-btn" onClick={this.handleCloseLogin}>×</button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="your email" autoFocus value={this.state.email} onChange={(e) =>
                          this.setState({ email: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" placeholder="Password" value={this.state.password} onChange={(e) =>
                          this.setState({
                            password: e.target.value
                          })}
                        />
                      </div>
                      <a href="#" onClick={this.handleShow}>Don't have an account? Signup</a>
                      <div className="modal-footer">
                        <button type="button" className="modal-btn modal-btn-cancel" onClick={this.handleCloseLogin}>Cancel</button>
                        <button type="submit" className="modal-btn modal-btn-primary" onClick={e =>
                          this.loginUser(e)}>Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* modal signup */}
          <div>
            {this.state.show && (
              <div className="modal-overlay" onClick={this.handleClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-header">
                    <h5 className="modal-title">Signup</h5>
                    <button className="close-btn" onClick={this.handleClose}>×</button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="your email" autoFocus value={this.state.email} onChange={(e) =>
                          this.setState({ email: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="firstName">First name</label>
                        <input type="firstName" id="firstName" placeholder="your first name"  value={this.state.firstName} onChange={(e) =>
                          this.setState({ firstName: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastName">Last name</label>
                        <input type="lastName" id="lastName" placeholder="your last name"  value={this.state.lastName} onChange={(e) =>
                          this.setState({ lastName: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input type="address" id="address" placeholder="your address"  value={this.state.address} onChange={(e) =>
                          this.setState({ address: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input type="phone" id="phone" placeholder="your phone"  value={this.state.phone} onChange={(e) =>
                          this.setState({ phone: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" placeholder="Password" value={this.state.password} onChange={(e) =>
                          this.setState({
                            password: e.target.value
                          })}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password confirmation</label>
                        <input type="password" id="confirmPassword" placeholder="Password confirmation" value={this.state.confirmPassword} onChange={(e) =>
                          this.setState({
                            confirmPassword: e.target.value
                          })}
                        />
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="modal-btn modal-btn-cancel" onClick={this.handleClose}>Cancel</button>
                        <button type="submit" className="modal-btn modal-btn-primary" onClick={e =>
                          this.createUser(e)}>Create new account</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )
    }

  }


}
