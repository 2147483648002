import React , {useState, useEffect} from "react";

//navigation
import { Navigate } from "react-router-dom";
import { URL, API_KEY } from '../links/links';
import showAlert from "../CustomComponents/customAlert"
export default class Logout extends React.Component{

  constructor(props){
    super(props);
    this.state={
      email: "",
      password : "",
      confirmPassword : "",
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      show: false,
      showLogin: false,
      showDelete: false,
      error: false,
      sent: null,
      items: [],
      session: "",
      userIsActive: "",
      isUserInactive: false,
      userEmail:"",
      user: null,
      dataIsLoaded: false,
    }
  }

  componentDidMount() {
    const loggedUser = localStorage.getItem("userEmail")
    if(loggedUser){
      const getUser = loggedUser
      this.setState({userEmail : getUser})

      const URLAPI = `${URL}/user/desactivate`;
        let headers = {
          'Content-Type': 'application/json',
          'key': API_KEY
        };
        
        let Data ={
          email : localStorage.getItem("userEmail")
        }
    
        fetch(URLAPI,{
          method:'PUT',
          headers:headers,
          body: JSON.stringify(Data) //convert data to JSON
          })
        .then(res => res.json())
        .then(
          (response)=>{
            if (response.status == 200){
              this.setState({
                isUserInactive: true
              })
            }else{
              showAlert({title: 'info', text: response.message,icon: 'warning',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
            }
            
          },
          (error) => {
            showAlert({title: 'error', text: 'error connexion to the server, please contacte the administrator',icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          }
        )
    }

    //clear storage
    localStorage.clear()
    this.setState({user : ""})
  }

   handleClose = ()  => this.setState({show: false});
   handleShow = ()  => this.setState({show: true});
   handleCloseLogin = ()  => this.setState({showLogin: false});
   handleShowLogin = ()  => this.setState({showLogin: true});


  render(){
    
    const {dataIsLoaded, items} = this.state;
    if(this.state.isUserInactive){
      return (
        <>
          <h1>  {this.state.userEmail} is login  </h1> 
        </>
        )
    
    }
    else{
      return(
        <>
          <Navigate to="/connexion" replace={true} />
        </>
      )
    }
   
  }

  
}
