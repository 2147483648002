import React from "react";
import { Helmet } from "react-helmet";

import { imageLinks, URL, API_KEY } from '../links/links';
import showAlert from "../CustomComponents/customAlert"
//get cache
import { MY_ARTICLE_CACHE } from '../json_cache/my_article'
import { registerVisit } from './registerVisit'

export default class Article extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      link: "",
      description: "",
      show: false,
      showDelete: false,
      error: false,
      sent: null,
      items: [],
      items_logged: [],
      items_default: [],
      dataIsLoaded: false,
      isLoading: true,
      user: "",
      viewOfUserLogout: true,
      openInfoForCandidate: false
    }

  }
  componentDidMount() {
    let Data = {
      page: "project"
    };

    registerVisit(Data)
    const loggedUser = localStorage.getItem("userEmail")
    if (loggedUser) {
      this.setState({ user: loggedUser })
    }
    if (loggedUser != null) {
      const URLAPI = `${URL}project?email=${loggedUser}`;

      let headers = {
        'Content-Type': 'application/json',
        'key': API_KEY
      };
      fetch(URLAPI, {
        method: 'GET',
        headers: headers
      })
        .then(res => res.json())
        .then(
          (response) => {
            if (response.status == 404) {
              this.setState({
                items: [],
                dataIsLoaded: false
              });
              return
            }
            this.setState({
              items: response.message,
              dataIsLoaded: true
            });
          },
          (error) => {
            showAlert({title: 'error', text: 'error connexion to the server, please contacte the administrator',icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
            this.setState({
              dataIsLoaded: false,
              error
            });
            throw new Error()
          }
        )
    }
    else {
      //use caching rather fetching data each time to reduce latency
      //todo: update this cache each 7 days 
      this.setState({
        items_default: MY_ARTICLE_CACHE,
        dataIsLoaded: true,
        isLoading: false,
      })
    }
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true, title: "", link: "", description: "" });

  //delete article
  handleCloseDelete = () => this.setState({ showDelete: false });
  handleShowDelete = () => this.setState({ showDelete: true, title: "", link: "", description: "" });

  /* submit form */
  CreateArticle(event) {
    event.preventDefault();
    
    const URLAPI = `${URL}project`;
    let headers = {
      'Content-Type': 'application/json',
      'key': API_KEY
    };

    if (!this.state.title || this.state.title.trim() === ''){
      showAlert({title: 'info', text: 'Missing mandatory column : title',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
      return
    }
    let Data = {
      title: this.state.title,
      link: this.state.link,
      description: this.state.description,
      email: this.state.user
    };
    //console.log(Data)

    fetch(URLAPI, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(Data) //convert data to JSON
    })
      .then(res => res.json())
      .then(
        (response) => {
          const code = response.status
          if (code == 201) {
            showAlert({title: 'success', text: 'Article crée avec succès',icon: 'success',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
            this.handleClose()
            this.componentDidMount()
          } else if (code == 409) {
            showAlert({title: 'info', text: 'Article existe déjà',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          }
        },
        (error) => {
          showAlert({title: 'error', text: 'Error connexion to the server, please contacte the administrator',icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          throw new Error('error connexion to the server, please contacte the administrator')
        }
      )
  }
  // delete one article
  DeleteArticle(event) {
    event.preventDefault();

    const URLAPI = `${URL}project`;
    let headers = {
      'Content-Type': 'application/json',
      'key': API_KEY
    };

    let Data = {
      title: this.state.title,
      link: this.state.link,
      email: this.state.user
    };

    fetch(URLAPI, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(Data) //convert data to JSON
    })
      .then(res => res.json())
      .then(
        (response) => {
          const code = response.status
          if (code == 404) {
            showAlert({title: 'info', text: "Article n'existe pas",icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          }else if (code == 400){
            showAlert({title: 'info', text: 'Missing mandatory column (title or link)',icon: 'info',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          } 
          else {
            showAlert({title: 'success', text: 'Article supprimé avec succès',icon: 'success',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false, position: 'top', timer: 3000})
            this.handleCloseDelete()
            this.componentDidMount()
          }
        },
        (error) => {
          showAlert({title: 'error', text: 'error connection to the server',icon: 'error',confirmButtonText: 'ok',showConfirmButton: true,showCancelButton: false})
          throw new Error()
        }
      )
  }

  handleSubmit(e) {
    e.preventDefault();
    this.handleClose();
  }
  render() {
    const { dataIsLoaded, isLoading, user, items, items_logged, items_default } = this.state;

    if (user) {
      if (!dataIsLoaded) {
        return (
          <>
            <div><h1>Veuillez ajouter des projets</h1></div>
            <button className="custom-primary-btn" onClick={this.handleShow}>Create project</button>
            {/* add project */}
            <div>
              {this.state.show && (
                <div className="modal-overlay" onClick={this.handleClose}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                      <h5 className="modal-title">Add project</h5>
                      <button className="close-btn" onClick={this.handleClose}>×</button>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input
                            type="text"
                            id="title"
                            placeholder="Title"
                            autoFocus
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="link">Link</label>
                          <input
                            type="text"
                            id="link"
                            placeholder="Link"
                            value={this.state.link}
                            onChange={(e) => this.setState({ link: e.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            id="description"
                            rows="4"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                          />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="modal-btn modal-btn-cancel" onClick={this.handleClose}>Cancel</button>
                          <button type="submit" className="modal-btn modal-btn-primary" onClick={e => this.CreateArticle(e)}>Create</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )
      } else {
        return (
          <>
            <Helmet>
              <title>Projects by Abdarrahmane NEINE - Software Engineer</title>
              <meta name="description" content="Explore the projects created by Abdarrahmane NEINE, focusing on software engineering, data structures, algorithms, and more." />
            </Helmet>
            <h4> {user}'s projects    </h4>
            <button className="custom-primary-btn" onClick={this.handleShow}>Create project</button>
            <button className="custom-danger-btn" onClick={this.handleShowDelete}>Delete project</button>
            <div className="card-container">
              {Array.isArray(items) && items.map((article, k) => (
                <div key={k} className="card">
                  <img className="card-img" src={imageLinks[article.image_id]} alt={article.title} />
                  <div className="card-body">
                    <h5 className="card-title">{article.title}</h5>
                    <p className="card-text">{article.description}</p>
                    <div className="card-footer">
                      <a className="card-btn" href={article.link}>
                        Code source {article.title}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* add project */}

            <div>
              {this.state.show && (
                <div className="modal-overlay" onClick={this.handleClose}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                      <h5 className="modal-title">Add project</h5>
                      <button className="close-btn" onClick={this.handleClose}>×</button>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input
                            type="text"
                            id="title"
                            placeholder="Title"
                            autoFocus
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="link">Link</label>
                          <input
                            type="text"
                            id="link"
                            placeholder="Link"
                            value={this.state.link}
                            onChange={(e) => this.setState({ link: e.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            id="description"
                            rows="4"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                          />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="modal-btn modal-btn-cancel" onClick={this.handleClose}>Cancel</button>
                          <button type="submit" className="modal-btn modal-btn-primary" onClick={e => this.CreateArticle(e)}>Create</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* modale delete */}
            <div>
              {this.state.showDelete && (
                <div className="modal-overlay" onClick={this.handleCloseDelete}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                      <h5 className="modal-title">Delete project</h5>
                      <button className="close-btn" onClick={this.handleCloseDelete}>×</button>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input
                            type="text"
                            id="title"
                            placeholder="Title"
                            autoFocus
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="link">Link</label>
                          <input
                            type="text"
                            id="link"
                            placeholder="Link"
                            value={this.state.link}
                            onChange={(e) => this.setState({ link: e.target.value })}
                          />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="modal-btn modal-btn-cancel" onClick={this.handleCloseDelete}>Cancel</button>
                          <button type="submit" className="modal-btn modal-btn-danger" onClick={e => this.DeleteArticle(e)}>Delete</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      }
    } else {
      //if recruter view the projects: he hav't an account => show default project
      if (dataIsLoaded) {
        if (isLoading) {
          return (
            <>
              <div>
                Loading data ...
              </div>
            </>
          )
        } else {
          return (
            <>
              <Helmet>
                <title>Projects by Abdarrahmane NEINE - Software Engineer</title>
                <meta name="description" content="Explore the projects created by Abdarrahmane NEINE, focusing on software engineering, data structures, algorithms, and more." />
              </Helmet>
              <div className="card-container">
                {items_default.map((article, k) => (
                  <div key={k} className="card">
                    <img className="card-img" src={imageLinks[article.image_id]} alt={article.title} />
                    <div className="card-body">
                      <h5 className="card-title">{article.title}</h5>
                      <p className="card-text">{article.description}</p>
                      <div className="card-footer">
                        <a className="card-btn" href={article.link}>
                          Code source {article.title}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
        }
      }
    }
  }
}
