export const MY_ARTICLE_CACHE = [
    {
        "id": "25",
        "title": "Image Recognition: (Deep Learning)",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/tensorflow_mnist_cnn",
        "image_id": "MachineLearning",
        "category": null,
        "description": "Construction of a convolutional neural network CNN with the database MNIST, and try to measure and test the performance. \nTechnical Stack : Python TensorFlow\/Keras\n",
        "created_at": "2022-08-19 12:48:53",
        "email": "kaberneine10@gmail.com"
    },
    {
        "id": "26",
        "title": "Data Visualization",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/Co2_emission\/blob\/main\/CO2_Emissions.ipynb",
        "image_id": "DataVisualization",
        "category": null,
        "description": "Visualize the CO2 emission in the world. This is a fany project to see how python is very useful for manipulating data.\nTechnical Stack: Python",
        "created_at": "2022-08-19 12:55:37",
        "email": "kaberneine10@gmail.com"
    },
    {
        "id": "27",
        "title": "Parking Data Governance: (Data Engineering)",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/ETL_TALEND",
        "image_id": "DataEngineer",
        "category": null,
        "description": "This is a Data Integration project, I have a data source system and I will put this data in a database. I am using an ETL ( Extract, Transform and Load) to get my data and insert it in my local database.\nTechnical Stack : Talend Open Studio, MySQL",
        "created_at": "2022-08-19 12:53:38",
        "email": "kaberneine10@gmail.com"
    },
    {
        "id": "28",
        "title": "Augmented Reality: (Team project)",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/AugmentedReality",
        "image_id": "AugmentedReality",
        "category": null,
        "description": "Develop an Android application for Augmented Reality. The application scans a specific image then displays a 3D Object. My coworker was working on the 3D Object with Blender.\nTechnical Stack: Viro React ,React Native , GitLab CI.",
        "created_at": "2022-08-19 12:58:26",
        "email": "kaberneine10@gmail.com"
    },
    {
        "id": "79",
        "title": "Dungeon Game",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/GameDungeon",
        "image_id": "DungeonGame",
        "category": null,
        "description": "Designed and implemented a dungeon game, demonstrating proficiency in object-oriented programming, using Java",
        "created_at": "2022-08-19 12:58:26",
        "email": "kaberneine10@gmail.com"
    },
    {
        "id": "80",
        "title": "Calendar",
        "link": "https:\/\/github.com\/Abdarrahmane-NEINE\/my_slot",
        "image_id": "Calendar",
        "category": null,
        "description": "Build a calendar that lets you add your availability and lets people reserve a slot with you using Django, React, SQLite.",
        "created_at": "2022-08-19 12:58:26",
        "email": "kaberneine10@gmail.com"
    }
]


