
import logo from './logo.svg';
import './App.css';

import { Helmet } from "react-helmet";
import React, { useContext, useEffect, useState } from "react";
// router, navigation
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useMatch, useParams } from "react-router-dom";

import About from './Components/About';
import Home from './Components/Home';
import Contact from './Components/Contact';
import Article from './Components/Article';
import SignUp from './Components/SignUp';
import Logout from './Components/Logout';


function App() {

  const [user, setUser] = useState("")
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const loggedUser = localStorage.getItem("userEmail")
    setUser(loggedUser)
  }, [])
  const currentYear = (new Date().getFullYear())
  return (<>
    <Helmet>
      <title>About Abdarrahmane NEINE - Software Engineer</title>
      <meta name="description" content="About Abdarrahmane NEINE, a mid-level software engineer with over two years of experience. Learn more about my journey, skills, and get in touch!" />
    </Helmet>
    <div className="App">
      <div className="navbar">
        <a className="navbar-brand" href="/">Software Expert</a>
        <div className="menu-center">
          <ul className="navbar-list">
            <li className="navbar-item">
              <NavLink className="navbar-link active" to="/about"
              >About Me</NavLink>
            </li>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/projects">Projects</NavLink>
            </li>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
        <div className="menu-right">
          {user ? (
            <ul className="navbar-list">
              <li className="navbar-item">
                <a className="navbar-link" href="/connexion">{localStorage.getItem("userEmail")}</a>
              </li>
              <li className="navbar-item">
                <a className="navbar-link" href="/deconnexion">Logout</a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-list">
              <li className="navbar-item">
                <NavLink className="navbar-link" to="/connexion">Login</NavLink>

              </li>
            </ul>
          )}
        </div>
        <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>
        <div className={`menu ${menuOpen ? 'active' : ''}`}>
          <ul className="navbar-list">
            <li className="navbar-item">
              <NavLink className="navbar-link active" to="/about">About Me</NavLink>
            </li>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/projects">Projects</NavLink>
            </li>
            <li className="navbar-item">
              <NavLink className="navbar-link" to="/contact">Contact</NavLink>
            </li>
          </ul>
          {user ? (
            <ul className="navbar-list">
              <li className="navbar-item">
                <a className="navbar-link" href="/connexion">{localStorage.getItem("userEmail")}</a>
              </li>
              <li className="navbar-item">
                <a className="navbar-link" href="/deconnexion">Logout</a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-list">
              <li className="navbar-item">
                <NavLink className="navbar-link" to="/connexion">Login</NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="main-content">
        <Routes>
          <Route path="/" element={<AboutMe />} />
          <Route path="/about" element={<AboutMe />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<ContactMe />} />
          <Route path="/connexion" element={<Connexion />} />
          <Route path="/deconnexion" element={<Deconnexion />} />
        </Routes>
      </div>
      <div className="footer">
        © {new Date().getFullYear()} Service - Developed by Abdarrahmane NEINE
      </div>
    </div>
  </>)

}


function AboutMe() {
  return (
    <>
      <About />
    </>
  );
}
function Connexion() {
  return (
    <>
      <main>
        <div className="about-container">
          <h1>Bien venu sur Software Expert</h1>
          <SignUp />
        </div>
      </main>
    </>
  );
}

function Deconnexion() {
  return (
    <>
      <main>
        <h2>logout</h2>
      </main>
      <Logout />
    </>
  );
}

function Projects() {
  return (
    <>
      <Article />
    </>
  );
}

function ContactMe() {
  return (
    <>
      <Contact />
    </>
  );
}
const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};



export default App;