import axios from 'axios'
import { URL, API_KEY} from '../links/links'

export const registerVisit = async (Data) => {
    try {
        const response = await axios.post(`${URL}visit/analytic`,
            Data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Key': API_KEY
                }
            }
        );
    } catch (error) {
        // add to log file
        // console.error('Error:', error);
    }
};
