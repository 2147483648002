import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <>
    <Helmet>
        <title>Contact Abdarrahmane NEINE - Software Engineer</title>
        <meta name="description" content="Get in touch with Abdarrahmane NEINE, a mid-level software engineer. Open for collaborations, freelance projects, and full-time opportunities." />
    </Helmet>
    <main className='main-contact'> 
        <h2>Contact</h2>
        <div className="contact-container">
        <div className="contact">
          <a href="https://www.linkedin.com/in/abdarrahmane-neine/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i> Connect on LinkedIn
          </a>
        </div>
        <div className="contact">
          <a href="mailto:abdarrahmane.neine@gmail.com" target="_blank" rel="noopener noreferrer">
            <i className="fas fa-envelope"></i> Email Me
          </a>
        </div>
        <div className="contact">
          <a href="https://github.com/Abdarrahmane-NEINE" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i> Github
          </a>
        </div>
        <div className="contact">
          <a href="https://stackoverflow.com/users/15793994/abdarrahmane-neine" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-stack-overflow"></i> Stackoverflow
          </a>
        </div>

        </div>
      </main>
    </>
  );
}
