import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import algoexpert_pdf from "../assets/pdf/AlgoExpert_Certificate.pdf";
import systemexpert_pdf from "../assets/pdf/SystemsExpert_Certificate.pdf";
import mooc_imt_badge from "../assets/pdf/mooc_imt_badge.pdf";
import linkedin_photo from "../images/linkedin_photo.png";
import { registerVisit } from './registerVisit'

export default function About() {
  useEffect(() => {
    let Data = {
      page: "about"
    };

    registerVisit(Data)
  }, []);

  function handleSpeech() {
    const audio = new Audio(process.env.PUBLIC_URL + '/audio/speech_name.m4a');
    audio.play();
  }

  return (
    <>
    {/* seo */}
      <Helmet>
        <title>About Abdarrahmane NEINE - Software Engineer</title>
        <meta name="description" content="About Abdarrahmane NEINE, a mid-level software engineer with over two years of experience. Learn more about my journey, skills, and get in touch!" />
      </Helmet>
      <main className="about-container">
      <header className="header">
        <div className="header-image">
          <img src={linkedin_photo} alt="Abdarrahmane NEINE" />
        </div>
        <div className="header-text">
          <span>Hello, I'm   
            <button
              onClick={handleSpeech}
              className="speech-button"
              aria-label="Pronounce my name"> 🔊  
            </button>
            <span className="my-principal-color"> Abdarrahmane NEINE</span>
          </span>
          <div>
            <span>I'm a </span>
            <span className="dynamic-text">software engineer with over three years of experience.</span>
          </div>
        </div>
      </header>
        <section className="info">
          <div >
            <h2 className="my-principal-color">Certifications:</h2>
            <ul>
              <li className="certif-item">
                <div className="certif-description">
                Completed comprehensive system design coursework and quiz, on
                <a href="https://www.algoexpert.io" target="_blank" rel="noopener noreferrer"> AlgoExpert.io</a>, 
                mastering core concepts that have significantly enhanced my ability to design robust, scalable, and efficient systems.
                </div>
                <a href={systemexpert_pdf} target="_blank" rel="noopener noreferrer">
                  <button className="my-button">System Design Certificate</button>
                </a>
              </li>
              <li className="certif-item">
                <div className="certif-description">
                  Completed more than 100 questions on
                  <a href="https://www.algoexpert.io" target="_blank" rel="noopener noreferrer"> AlgoExpert.io</a>, refined my skills in Python, C++, data structures and algorithms
                  enhancing my problem-solving capabilities. 
                </div>
                <a href={algoexpert_pdf} target="_blank" rel="noopener noreferrer">
                  <button className="my-button">AlgoExpert Certificate</button>
                </a>
              </li>
              <li className="certif-item">
                <div className="certif-description">
                  MOOC (Massive open online course) Big Data on
                  <a href="https://www.fun-mooc.fr/en/cours/fondamentaux-pour-le-big-data/" target="_blank" rel="noopener noreferrer"> INSTITUT MINES-TÉLÉCOM (IMT)</a>,
                  topics covered include Linear Algebra, Probability, Statistics, and Numpy.
                </div>
                <a href={mooc_imt_badge} target="_blank" rel="noopener noreferrer">
                  <button className="my-button">IMT Certificate</button>
                </a>
              </li>
            </ul>
          </div>
          {/* <a href={my_resume} target="_blank" rel="noopener noreferrer">
            <button className="my-button">Download My Resume</button>
          </a> */}
          <div className="get-in-touch-container">
            <h2 className="my-principal-color">Get in touch</h2>
            <p>Feel free to
              <a href="mailto:abdarrahmane.neine@gmail.com" target="_blank" rel="noopener noreferrer"> email me </a>,
              or connect with me on
              <a href="https://www.linkedin.com/in/abdarrahmane-neine/" target="_blank" rel="noopener noreferrer"> LinkedIn </a>.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
